<template>
  <div class="role-definitions-editor">
    <div class="px-0 py-0">
      <div class="role-definitions-editor-title">
        <h3>{{ title }}</h3>
      </div>
      <v-container fluid>
        <v-combobox
          id="roles-combobox"
          v-model="model"
          multiple
          variant="solo"
          hint="Simply enter new role that you need and/or delete the role you don't need and then click the Apply Button."
          :disabled="!isCompliSpaceStaff"
          append-icon=""
        >
          <template #selection="{ item }">
            <v-chip v-if="item === Object(item)" label size="small">
              <span class="pr-2 role-text">
                {{ item.value.text }}
              </span>
              <v-icon
                v-if="isCompliSpaceStaff"
                class="role-text-delete"
                size="small"
                @click="onRoleDeleteIcon(item)"
              >
                $delete
              </v-icon>
            </v-chip>
          </template>
        </v-combobox>
        <div v-if="isCompliSpaceStaff" class="edit-role-actions">
          <v-spacer></v-spacer>
          <cs-button
            id="save-role-definitions-btn"
            primary
            class="mb-2"
            :disabled="!dirty"
            label="Save"
            @click="onSaveRoleBtn"
          >
          </cs-button>
        </div>
      </v-container>
    </div>

    <cs-form-dialog
      id="role-definitions-editor-confirm-delete-dlg"
      v-model="confirmationDialog"
      heading="Delete Role Confirmation"
      :primary-action="{ label: 'No' }"
      :secondary-action1="{ label: 'Yes & Apply' }"
      @secondary1-click="onApply"
      @primary-click="confirmationDialog = false"
    >
      <template #cs-form-dialog-content>
        <div id="delete-confirmation-message">This action cannot be undone. Are you sure?</div>
      </template>
    </cs-form-dialog>
    <cs-form-dialog
      id="role-definitions-editor-confirm-stop-dlg"
      v-model="stopDialog"
      heading="Action cannot be done"
      text=""
      :primary-action="{ label: 'Ok' }"
      @primary-click="stopDialog = false"
    >
      <template #cs-form-dialog-content>
        <div id="stop-confirmation-message">
          This action cannot be done, because the role has been assigned to at least one user.
        </div>
      </template>
    </cs-form-dialog>
  </div>
</template>

<script>
import { CSBase } from '@complispace/cs-design-system';
import { mapGetters, mapMutations, mapState } from 'vuex';
import * as MutationTypes from '@/store/mutationTypes';
import OrganizationProp from '@/props/OrganizationProp';
import { Organization } from '@/models';
import { soul } from '@/dependency-injection';
import flattenDeep from 'lodash/flattenDeep';

export default {
  name: 'RoleDefinitionsEditor',

  extends: CSBase,

  props: {
    organization: {
      type: OrganizationProp,
      required: false,
      default: undefined
    }
  },

  data() {
    return {
      dirty: false,
      confirmationDialog: false,
      stopDialog: false,
      selectedRoleToBeConfirmDelete: null
    };
  },

  computed: {
    ...mapGetters({
      isCompliSpaceStaff: 'authorization/isCompliSpaceStaff',
      isClientAdmin: 'authorization/isClientAdmin'
    }),

    ...mapState({
      users: (state) => state.users.users,
      connections: (state) => state.organization.connections
    }),

    title() {
      const organizationDisplayName =
        (this.organization && this.organization.displayName) || undefined;

      if (organizationDisplayName && (this.isCompliSpaceStaff || this.isClientAdmin)) {
        return `${organizationDisplayName} roles`;
      }

      return '';
    },

    model: {
      get() {
        return this.organization && this.organization.roleDefinitions
          ? this.organization.roleDefinitions.map((it) => ({ text: it }))
          : [];
      },
      set(value) {
        this.dirty = true;
        const roleDefinitions = value.map((it) => {
          return typeof it === 'string' ? it : it.text;
        });

        const newOrganization = Organization(
          this.organization.id,
          this.organization.name,
          this.organization.displayName,
          roleDefinitions,
          this.organization.appUrls
        );
        this.setOrganization(newOrganization);
      }
    }
  },

  methods: {
    async onSaveRoleBtn() {
      this.dirty = false;
      this.setLoading(true);
      try {
        await this.$store.dispatch('organization/saveRoleDefinitions');
        await this.$store.dispatch('organization/init');
        this.clearLoading();
      } catch (err) {
        this.clearLoading();
        this.showErrorAlert(err.message, true);
      }
    },

    async onRoleDeleteIcon(roleItem) {
      this.dirty = false;
      try {
        const tasks = this.connections.map((connection) =>
          soul
            .getAllUsersByConnectionName(connection.name)
            .then((users) => users.filterByRole(roleItem.value.text))
        );
        const filteredList = await Promise.all(tasks);
        const usersWithTheRole = flattenDeep(filteredList);
        if (usersWithTheRole.length > 0) {
          this.stopDialog = true;
        } else {
          this.selectedRoleToBeConfirmDelete = roleItem.value.text;
          this.confirmationDialog = true;
        }
      } catch (err) {
        console.error(err.message);
        this.showErrorAlert(
          'Internal Error occured when deleting the role: please contact Complispace Staff',
          true
        );
      }
    },

    async onApply() {
      this.setLoading(true);
      try {
        const role = this.selectedRoleToBeConfirmDelete;
        await soul.deleteRoleByOrganizationId(this.organization.id, role);
        this.selectedRoleToBeConfirmDelete = null;
        this.confirmationDialog = false;
        this.dirty = false;
        await this.$store.dispatch('organization/init');
        this.dirty = false;
        await this.$nextTick();
        this.showSuccessAlert(`${role} is deleted`);
      } catch (err) {
        this.clearLoading();
        this.showErrorAlert(err.message, true);
      }
      this.clearLoading();
    },

    ...mapMutations({
      setOrganization: MutationTypes.ORGANIZATION_SET_ORGANIZATION
    })
  }
};
</script>

<style scoped>
.content {
  padding: 8px 16px;
}

.edit-role-actions {
  display: flex;
}

.container {
  padding: 0;
}

.role-definitions-editor-title {
  min-width: 1px;
  height: 32px !important;
}
</style>
