import parser from 'csv-parse/lib/browser/sync';
import stringify from 'csv-stringify/lib/browser/sync';
import createSoulApi from '@/services/soul-api';
import createCsvService from '@/services/csv';
import parseUsersService from '@/services/parseUsers';
import uploadedUsersService from '@/services/uploaded-users';
import validateUser from '@/services/validateUser';

import createRequest from '@/helpers/request';

import Assert from '@/helpers/assert';
import { axios } from '@complispace/cs-design-system';

Assert.isPresent(process.env.VUE_APP_AUTH0_AUDIENCE, 'VUE_APP_AUTH0_AUDIENCE');
const soulApiBaseUrl = process.env.VUE_APP_AUTH0_AUDIENCE;

const soulApiRequest = createRequest(soulApiBaseUrl, axios);

const reader = new FileReader();
const csv = createCsvService(parser, reader, stringify);

const parseUsers = parseUsersService(csv);
const uploadedUsers = uploadedUsersService(window.localStorage);
const soul = createSoulApi(soulApiRequest);

// eslint-disable-next-line import/prefer-default-export
export { soul, csv, parseUsers, validateUser, uploadedUsers, soulApiRequest };
