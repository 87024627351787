import pick from 'lodash/pick';

export const keys = ['id', 'name', 'displayName'];
export const headerTexts = ['Id', 'Name', 'Display Name'];
const actionsHeader = { title: 'User Directory Url', key: 'userDirectory', sortable: false };

const getters = {
  organizationsView(state) {
    const { organizations } = state;
    const itemsWithNoUrl = [];

    if (organizations.length) {
      const items = organizations.map((organization) => {
        const base = pick(organization, [...keys]);
        base.userDirectory = '';
        let hasNoUdAppUrl = false;

        if (organization.appUrls && organization.appUrls.length) {
          const udAppUrl = organization.appUrls.find((appUrl) => !!appUrl.userDirectory);

          if (udAppUrl) {
            base.userDirectory = udAppUrl.userDirectory;
          } else {
            hasNoUdAppUrl = true;
          }
        } else {
          hasNoUdAppUrl = true;
        }

        const viewItem = {
          ...base
        };
        if (hasNoUdAppUrl) {
          itemsWithNoUrl.push(viewItem);
        }
        return viewItem;
      });
      const headers = keys.map((value, index) => {
        const title = headerTexts[index];
        const col = { title, key: value };
        return col;
      });
      return {
        headers: headers.concat(actionsHeader),
        items,
        itemsWithNoUrl
      };
    }

    return {
      headers: [],
      items: [],
      itemsWithNoUrl
    };
  }
};

export default getters;
