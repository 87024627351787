<template>
  <div :id="id" class="reset-user-passwords">
    <cs-button
      id="reset-password-users-btn"
      class="reset-password-users-btn"
      :disabled="!canRunBulkAction"
      label="Reset Password"
      @click="onResetPasswordClick"
    ></cs-button>

    <invalid-connection-users-warning-dialog
      id="invalid-connection-reset-users-password-users-dialog"
      v-model="showWarningDialog"
      :connection-name="connectionName"
      table-id="invalid-connection-reset-users-password-table"
      :users="selected"
      @dismiss="dismissWarningDialog"
    ></invalid-connection-users-warning-dialog>

    <app-url-selector-dialog
      id="reset-users-password-app-url-dialog"
      ref="bulk-reset-app-urls-dialog"
      v-model="showAppUrlDialog"
      confirm-label="Continue"
      cancel-label="Cancel"
      heading="Select Application Url"
      hint="Please select the redirect URL that user will be navigated to after resetting the password."
      label="Application Url after reset password"
      selector-id="users-reset-password-app-url-selector"
      @app-url-confirm="onAppUrlConfirm"
      @app-url-cancel="onAppUrlCancel"
    ></app-url-selector-dialog>

    <confirm-users-dialog
      id="reset-users-password-confirm-dialog"
      v-model="showConfirmDialog"
      :headers="headers"
      heading="Reset Password"
      :selected-users="selected"
      search-id="reset-users-confirm-search-text-field"
      table-id="reset-users-password-confirm-table"
      @confirm-users="onConfirm"
      @confirm-users-cancel="onCancel"
    ></confirm-users-dialog>

    <progress-dialog
      id="reset-users-password-progress-dialog"
      ref="reset-users-password-progress-dialog"
      cancel-btn-label="Cancel"
      heading="Resetting Users Password"
      progress-bar-id="reset-users-password-progress-bar"
      :model-value="showProgressDialog"
      :progress-percent="progressPercent"
      @progress-cancel="onProgressDialogCancel"
    ></progress-dialog>
  </div>
</template>
<script>
import AppUrlSelectorDialog from '@/components/AppUrlSelectorDialog';
import BulkActionBase from '@/components/bulkActions/BulkActionBase';
import ConfirmUsersDialog from '@/components/ConfirmUsersDialog';

import componentErrorHandler from '@/helpers/componentErrorHandler';
import InvalidConnectionUsersWarningDialog from '@/components/InvalidConnectionUsersWarningDialog';
import ProgressDialog from '@/components/ProgressDialog';
import { mapGetters } from 'vuex';
import BulkActionName from './bulkActionName';

export default {
  name: 'ResetUserPasswords',

  components: {
    'app-url-selector-dialog': AppUrlSelectorDialog,
    'confirm-users-dialog': ConfirmUsersDialog,
    'invalid-connection-users-warning-dialog': InvalidConnectionUsersWarningDialog,
    'progress-dialog': ProgressDialog
  },
  extends: BulkActionBase,

  props: {
    connectionName: {
      type: String,
      required: true
    },

    selected: {
      type: Array,
      required: true
    },

    headers: {
      type: Array,
      required: true
    }
  },

  emits: ['reset-user-passwords-completed'],

  data() {
    return {
      id: this.$attrs.id,
      showConfirmDialog: false,
      showAppUrlDialog: false,
      redirectUrl: ''
    };
  },

  computed: {
    ...mapGetters({
      appUrlsView: 'organization/appUrlsView'
    }),

    hasAppUrls() {
      const appUrls = Object.values(this.appUrlsView);
      return appUrls.length > 0;
    }
  },

  methods: {
    onAppUrlCancel() {
      this.redirectUrl = '';
    },

    resetAppUrlSelector() {
      this.$refs['bulk-reset-app-urls-dialog'].reset();
    },

    async onAppUrlConfirm(redirectUrl) {
      this.redirectUrl = redirectUrl;

      this.resetAppUrlSelector();
      this.showAppUrlDialog = false;

      this.showConfirmDialog = true;

      await this.$nextTick();
      this.showWarningAlert(
        'Are you sure you want to request a password reset for the selected users? This action cannot be undone.',
        true
      );
    },

    async onConfirm() {
      this.showConfirmDialog = false;

      this.initBulkActionProgress(BulkActionName.RESET_USERS_PASSWORD);
      await this.selected.reduce(this.runActionOnUsers, undefined);

      this.toggleProgressDialog(false);
      this.setProgressPercent(0);

      const failedUsersLen = this.failedBulkActionUsers.length;
      const message = this.getBulkActionMessage();

      this.showBulkActionAlert(message, failedUsersLen);

      this.redirectUrl = '';
      this.clearBulkActionState();

      this.$emit('reset-user-passwords-completed', {
        failedUsersLen
      });
    },

    onCancel() {
      this.redirectUrl = '';
      this.showConfirmDialog = false;
    },

    async onResetPasswordClick() {
      if (this.containsUsersWithMismatchedConnections) {
        await this.checkForUsersWithMismatchedConnections();
      }

      if (!this.hasAppUrls) {
        componentErrorHandler(
          this,
          undefined,
          'Please contact Complispace Admin to setup the app urls for the organization.',
          false
        );
        return;
      }

      this.redirectUrl = '';
      this.showAppUrlDialog = true;
    }
  }
};
</script>
<style scoped>
@media (max-width: 1264px) {
  #reset-password-users-btn {
    min-width: 100%;
  }
}
</style>
