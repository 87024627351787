import {
  ApiError,
  BadRequest,
  Forbidden,
  NetworkError,
  NotFound,
  ServerError
} from '@/helpers/api-errors';

export default function apiErrorHandler(error) {
  const { isAxiosError, response, message } = error;

  if (!isAxiosError) {
    throw error;
  }

  if (message === 'Network Error') {
    throw new NetworkError('Soul service unavailable');
  }

  const { status } = response || {};

  switch (status) {
    case 400:
      throw new BadRequest(message);

    case 403:
      throw new Forbidden(message);

    case 404:
      throw new NotFound(message);

    case 500:
      throw new ServerError(message);

    default: {
      const { statusText } = response;
      throw new ApiError(message, status, statusText);
    }
  }
}
