<template>
  <div :id="id" class="de-assign-roles">
    <v-tooltip
      id="de-assign-roles-tooltip"
      v-model="showTooltip"
      class="de-assign-roles-tooltip"
      location="top"
    >
      <template #activator="{ props }">
        <div v-bind="props">
          <cs-button
            id="de-assign-roles-btn"
            :disabled="!canRunBulkAction"
            class="de-assign-roles-btn"
            :label="label"
            @click="onClickDeAssignRoles"
          >
          </cs-button>
        </div>
      </template>
      <span>{{ tooltip }}</span>
    </v-tooltip>

    <invalid-connection-users-warning-dialog
      id="invalid-connection-de-assign-roles-dialog"
      v-model="showWarningDialog"
      :connection-name="connectionName"
      table-id="invalid-connection-de-assign-roles-table"
      :users="selected"
      @dismiss="dismissWarningDialog"
    />

    <user-roles-select-checkbox-dialog
      id="show-users-de-assign-roles-dialog"
      v-model="showConfirmDialog"
      :heading="label"
      :primary-label="primaryLabel"
      checkbox-hint="De-assign roles from the user"
      secondary-label="Cancel"
      @edit-roles="onConfirm"
      @cancel-roles="onCancel"
    />

    <progress-dialog
      id="de-assign-roles-progress-dialog"
      cancel-btn-label="Cancel"
      heading="De-assigning Roles From Users"
      progress-bar-id="de-assign-roles-progress-bar"
      :model-value="showProgressDialog"
      :progress-percent="progressPercent"
      @progress-cancel="onProgressDialogCancel"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import BulkActionBase from '@/components/bulkActions/BulkActionBase';
import BulkActionName from '@/components/bulkActions/bulkActionName';
import componentErrorHandler from '@/helpers/componentErrorHandler';
import delay from '@/helpers/delay';

import InvalidConnectionUsersWarningDialog from '@/components/InvalidConnectionUsersWarningDialog';
import ProgressDialog from '@/components/ProgressDialog';
import UserRolesSelectCheckboxDialog from '@/components/UserRolesSelectCheckboxDialog';

export default {
  name: 'DeAssignRoles',

  components: {
    'invalid-connection-users-warning-dialog': InvalidConnectionUsersWarningDialog,
    'user-roles-select-checkbox-dialog': UserRolesSelectCheckboxDialog,
    'progress-dialog': ProgressDialog
  },
  extends: BulkActionBase,

  props: {
    connectionName: {
      type: String,
      required: true
    },

    selected: {
      type: Array,
      required: true
    }
  },

  emits: ['de-assign-roles-completed'],

  data() {
    return {
      label: 'De-assign Role(s)',
      primaryLabel: 'De-Assign',
      tooltip: 'Please select at least one user for de-assigning role(s)',

      id: this.$attrs.id,
      showTooltip: true,
      showConfirmDialog: false
    };
  },

  computed: {
    ...mapGetters({
      roles: 'organization/roles'
    }),

    hasRoles() {
      return this.roles.length > 0;
    }
  },

  async mounted() {
    await delay(1000);
    this.showTooltip = false;
  },

  methods: {
    async onClickDeAssignRoles() {
      if (!this.hasRoles) {
        componentErrorHandler(
          this,
          undefined,
          'Please contact Complispace Admin to setup the roles for the organization.',
          true
        );
        return;
      }

      if (this.containsUsersWithMismatchedConnections) {
        await this.checkForUsersWithMismatchedConnections();
        return;
      }

      this.showConfirmDialog = true;

      await this.$nextTick();
      this.showWarningAlert(
        'Are you sure you want to de-assign roles. This action cannot be undone.',
        true
      );
    },

    async onConfirm(selectedRoles) {
      if (!selectedRoles.length) {
        await this.$nextTick();
        componentErrorHandler(this, undefined, 'You need to select at least one role.', true);
        return;
      }

      this.selectedRoles = selectedRoles;
      this.showConfirmDialog = false;

      this.initBulkActionProgress(BulkActionName.DEASSIGN_ROLES_FROM_USERS);

      await this.selected.reduce(this.runActionOnUsers, undefined);

      this.toggleProgressDialog(false);
      this.setProgressPercent(0);

      const failedUsersLen = this.failedBulkActionUsers.length;
      const message = this.getBulkActionMessage();

      this.showBulkActionAlert(message, failedUsersLen);

      this.selectedRoles = [];
      this.clearBulkActionState();

      this.$emit('de-assign-roles-completed', {
        failedUsersLen,
        successfulUsersLen: this.successfulBulkActionUsers.length
      });
    },

    onCancel() {
      this.showConfirmDialog = false;
    }
  }
};
</script>
<style scoped>
@media screen and (max-width: 1264px) {
  #de-assign-roles-btn {
    min-width: 100%;
  }
}
</style>
