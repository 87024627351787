<template>
  <div :id="id" class="delete-users">
    <cs-button
      id="delete-users-btn"
      class="delete-users-btn"
      :disabled="!canRunBulkAction"
      label="Delete Users"
      @click="onDeleteUsersClick"
    ></cs-button>

    <confirm-users-dialog
      id="delete-users-confirm-dialog"
      v-model="showConfirmDialog"
      class="delete-users-confirm-dialog"
      :headers="headers"
      heading="Delete Users"
      search-id="delete-users-confirm-search-text-field"
      :selected-users="selected"
      table-id="delete-users-confirm-table"
      @confirm-users="onConfirm"
      @confirm-users-cancel="onCancel"
    />

    <invalid-connection-users-warning-dialog
      id="invalid-connection-delete-users-dialog"
      v-model="showWarningDialog"
      :connection-name="connectionName"
      table-id="invalid-connection-delete-users-table"
      :users="selected"
      @dismiss="dismissWarningDialog"
    />

    <progress-dialog
      id="delete-users-progress-dialog"
      cancel-btn-label="Cancel"
      heading="Deleting Users"
      progress-bar-id="delete-users-progress-bar"
      :model-value="showProgressDialog"
      :progress-percent="progressPercent"
      @progress-cancel="onProgressDialogCancel"
    />
  </div>
</template>

<script>
import BulkActionBase from '@/components/bulkActions/BulkActionBase';
import BulkActionName from '@/components/bulkActions/bulkActionName';

import ConfirmUsersDialog from '@/components/ConfirmUsersDialog';

import InvalidConnectionUsersWarningDialog from '@/components/InvalidConnectionUsersWarningDialog';
import ProgressDialog from '@/components/ProgressDialog';

export default {
  name: 'DeleteUsers',

  components: {
    'confirm-users-dialog': ConfirmUsersDialog,
    'progress-dialog': ProgressDialog,
    'invalid-connection-users-warning-dialog': InvalidConnectionUsersWarningDialog
  },
  extends: BulkActionBase,

  props: {
    connectionName: {
      type: String,
      required: true
    },

    selected: {
      type: Array,
      required: true
    },

    headers: {
      type: Array,
      required: true
    }
  },

  emits: ['delete-users-completed'],

  data() {
    return {
      id: this.$attrs.id,
      showConfirmDialog: false
    };
  },

  methods: {
    async onDeleteUsersClick() {
      if (this.containsUsersWithMismatchedConnections) {
        await this.checkForUsersWithMismatchedConnections();
        return;
      }

      this.showConfirmDialog = true;

      await this.$nextTick();
      this.showWarningAlert(
        'Are you sure you want to delete the selected users? This action cannot be undone.',
        true
      );
    },

    async onConfirm() {
      this.showConfirmDialog = false;

      this.initBulkActionProgress(BulkActionName.DELETE_USERS);
      await this.selected.reduce(this.runActionOnUsers, undefined);

      this.toggleProgressDialog(false);
      this.setProgressPercent(0);

      const failedUsersLen = this.failedBulkActionUsers.length;
      const message = this.getBulkActionMessage();

      this.showBulkActionAlert(message, failedUsersLen);
      this.clearBulkActionState();

      this.$emit('delete-users-completed', {
        failedUsersLen,
        successfulUsersLen: this.successfulBulkActionUsers.length
      });
    },

    onCancel() {
      this.showConfirmDialog = false;
    }
  }
};
</script>
<style scoped>
@media (max-width: 1264px) {
  #delete-users-btn {
    min-width: 100%;
  }
}
</style>
