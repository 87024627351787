import Assert from '@/helpers/assert';

const Organization = (id, name, displayName, roleDefinitions, appUrls) => ({
  id: Assert.isPresent(id, 'id'),
  name: Assert.isPresent(name, 'name'),
  displayName: Assert.isPresent(displayName, 'displayName'),
  roleDefinitions: Assert.isPresent(roleDefinitions, 'roleDefinitions'),
  appUrls: Assert.isPresent(appUrls, 'appUrls')
});

export default Organization;
