<template>
  <cs-app
    :show-header="isLoggedIn"
    :side-nav-width="sideNavWidth"
    :feedback-btn="feedbackBtn"
    :help-btn="helpBtn"
    :search-btn="searchBtn"
    :update-btn="updateBtn"
    :view-btn="viewBtn"
    :version="version"
    @feedback-btn-click="feedbackBtnAction"
    @help-btn-click="helpBtnAction"
    @search-btn-click="searchBtnAction"
    @update-btn-click="updateBtnAction"
    @view-btn-click="viewBtnAction"
  >
    <template #header-menu> </template>

    <template #header-left> </template>

    <template #header-right> </template>

    <template #header-helper> </template>

    <template #header-settings> </template>

    <template #header-settings-menu> </template>

    <template #side-nav>
      <left-navigation />
    </template>

    <template #router-view>
      <router-view v-slot="{ Component }">
        <v-slide-y-transition>
          <component :is="Component" />
        </v-slide-y-transition>
      </router-view>
    </template>

    <template #footer>
      <div class="content">
        <v-container>
          <v-row>
            <v-img :src="csLogo" class="cs-logo"> </v-img>
          </v-row>
          <v-row>
            <span>
              &copy;{{ copyRightYearRange }} CompliSpace (v{{ version }}). All rights reserved.
            </span>
          </v-row>
        </v-container>
      </div>
    </template>
  </cs-app>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import {
  CSBase,
  CSHeaderButtonProps,
  CSHeaderActiveButtonProps
} from '@complispace/cs-design-system';
import LeftNavigation from '@/navigations/LeftNavigation';
import csLogo from './assets/complispace-logo.svg';
import { version } from '../package.json';
import * as MutationTypes from './store/mutationTypes';

export default {
  name: 'App',
  components: {
    'left-navigation': LeftNavigation
  },

  extends: CSBase,

  data() {
    return {
      csLogo,
      hideSideNav: false
    };
  },

  computed: {
    ...mapGetters({
      isLoggedIn: 'authorization/isLoggedIn'
    }),

    ...mapState({
      viewTypeStore: 'view-type'
    }),

    feedbackBtn() {
      return new CSHeaderButtonProps('feedback-btn');
    },

    searchBtn() {
      return new CSHeaderActiveButtonProps('search-btn');
    },

    updateBtn() {
      return new CSHeaderButtonProps('check-for-updates', 'Check for Updates');
    },

    viewBtn() {
      return new CSHeaderActiveButtonProps('admin-view', 'Administrator View');
    },

    helpBtn() {
      return new CSHeaderButtonProps('help-btn');
    },

    sideNavWidth() {
      return this.isLoggedIn && !this.hideSideNav ? 300 : 0;
    },

    version() {
      return version;
    },

    copyRightYearRange() {
      const startYear = '2018';
      return `${startYear}-${new Date().getFullYear().toString()}`;
    }
  },

  created() {
    this.setViewTitle('CS User Directory');
  },

  mounted() {
    document.title = this.$store.getters['appearance/viewTitle'] || 'Complispace User Directory';
    const appHostSplits = window.location.host.split('.');
    this.setAppName(appHostSplits[0].toUpperCase() || '');
  },

  methods: {
    ...mapMutations({
      setPageViewType: MutationTypes.VIEW_TYPE_SET_PAGE_VIEW_TYPE,
      setAppName: MutationTypes.APP_SET_NAME
    }),

    feedbackBtnAction() {
      // eslint-disable-next-line no-alert
      alert('feedback button action executed!');
    },

    helpBtnAction() {
      // eslint-disable-next-line no-alert
      alert('help button action executed!');
    },

    searchBtnAction() {
      // eslint-disable-next-line no-alert
      alert('search button action executed!');
    },

    updateBtnAction() {
      // eslint-disable-next-line no-alert
      alert('update button action executed!');
    },

    viewBtnAction() {
      // eslint-disable-next-line no-alert
      alert('view button action executed!');
    }
  }
};
</script>

<style>
.v-footer.theme--light.v-sheet {
  background-color: white;
}

.v-footer {
  justify-content: space-around;
}
</style>
<style scoped>
.cs-logo {
  width: 200px;
  height: 40px;
}
</style>
