<template>
  <div class="upload-users">
    <cs-button
      id="upload-users-clear-btn"
      class="ml-2"
      label="Reset"
      @click.prevent="clearFile"
    ></cs-button>

    <cs-button
      id="upload-users-btn"
      class="ml-2"
      :primary="!canUploadUsers ? false : true"
      :disabled="!canUploadUsers"
      label="Upload"
      @click="onFileUploadClick"
    >
    </cs-button>

    <div style="display: none">
      <v-file-input
        id="upload-users-input"
        ref="fileInput"
        v-model="file"
        :disabled="!canUploadUsers"
        accept=".csv"
        hide-input
        @change="onFileUploadChange"
      >
      </v-file-input>
    </div>

    <duplicate-users-warning-dialog
      id="duplicate-user-emails-dialog"
      heading="Duplicate User Emails"
      message="The csv file has duplicate users with the emails:"
      :model-value="showDuplicateEmailsDialog"
      :users="duplicateUsers || []"
      @dismiss="onDuplicatesInfoClose"
    ></duplicate-users-warning-dialog>
  </div>
</template>
<script>
import DuplicateUsersWarningDialog from '@/components/DuplicateUsersWarningDialog';
import { CSBase } from '@complispace/cs-design-system';
import { mapActions, mapState } from 'vuex';

import componentErrorHandler from '@/helpers/componentErrorHandler';

export default {
  name: 'CsvUpload',

  components: {
    'duplicate-users-warning-dialog': DuplicateUsersWarningDialog
  },
  extends: CSBase,

  data() {
    return {
      file: null,
      hasUploadFileError: false,
      showDuplicateEmailsDialog: false
    };
  },

  computed: {
    ...mapState({
      createdUsers: (state) => state.uploadedUsers.createdUploadedUsers,
      failedUsers: (state) => state.uploadedUsers.failedUploadedUsers,
      uploadedUsers: (state) => state.uploadedUsers.uploadedUsers,
      duplicateUsers: (state) => state.uploadedUsers.duplicatedUploadedUsers
    }),

    hasCreatedUsers() {
      return Array.isArray(this.createdUsers) && this.createdUsers.length > 0;
    },

    hasFailedUsers() {
      return Array.isArray(this.failedUsers) && this.failedUsers.length > 0;
    },

    isReportPrimaryAction() {
      return (this.hasCreatedUsers || this.hasFailedUsers) && !this.hasUploadedUsers;
    },

    hasUploadedUsers() {
      return Array.isArray(this.uploadedUsers) && this.uploadedUsers.length > 0;
    },

    canUploadUsers() {
      return !(this.hasUploadFileError || this.hasUploadedUsers || this.isReportPrimaryAction);
    }
  },

  methods: {
    ...mapActions({
      initUploadedUsers: 'uploadedUsers/initUploadedUsers',
      resetUploadedUsers: 'uploadedUsers/resetUploadedUsers'
    }),

    async onFileUploadClick() {
      if (!this.file) {
        await this.$refs.fileInput.click();
      }
    },

    async onFileUploadChange() {
      try {
        this.setLoading(true);
        await this.resetUploadedUsers();
        await this.initUploadedUsers(this.file?.[0]);

        if (this.duplicateUsers.length > 0) {
          this.showDuplicateUsersWarning();
        } else if (this.hasUploadedUsers) {
          this.showUploadedUsersAlert();
        }

        this.hasUploadFileError = false;
        this.clearLoading();
      } catch (e) {
        this.hasUploadFileError = true;
        this.showUploadedUsersError(e);
      }
    },

    showDuplicateUsersWarning() {
      if (this.duplicateUsers.length > 0 && this.duplicateUsers.length <= 2) {
        const duplicateEmails = this.duplicateUsers.getEmails().join(', ');
        this.showWarningAlert(`Duplicate users found when creating users: ${duplicateEmails}.`);
      }

      if (this.duplicateUsers.length > 2) {
        this.showDuplicateEmailsDialog = true;
      }
    },

    onDuplicatesInfoClose() {
      this.showDuplicateEmailsDialog = false;
    },

    showUploadedUsersAlert() {
      if (this.uploadedUsers.hasUpperCaseEmails()) {
        this.showWarningAlert(
          'For one or more users with emails containing uppercase characters, emails will be converted to lowercase when saved.'
        );
      } else {
        this.showSuccessAlert(
          'Uploaded csv file successfully. To create users, click the Create Users button.'
        );
      }
    },

    clearFile() {
      this.resetUploadedUsers();

      this.file = null;
      this.hasUploadFileError = false;

      this.clearLoading();
      this.clearAlert();
    },

    showUploadedUsersError(e) {
      const errorMessageMapping = {
        'connection name is invalid.':
          "At least one uploaded user's connection name does not match this connection.",
        'some roles are invalid.':
          "At least one uploaded user's roles have at least one invalid role.",
        'email does not have "@".': "At least one uploaded user's email is not invalid."
      };

      const errorMessage = errorMessageMapping[e.message] || e.message;

      componentErrorHandler(
        this,
        e,
        `${errorMessage} Please click "Reset" button before re-upload again.`,
        false
      );
    }
  }
};
</script>

<style scoped>
@media screen and (max-width: 1264px) {
  #upload-users-clear-btn {
    min-width: 100%;
  }
  #upload-users-btn {
    min-width: 100%;
  }
  .upload-users {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    padding-right: 8px;
  }
}
</style>
