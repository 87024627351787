<template>
  <div class="app-urls-editor">
    <div class="px-0 py-0">
      <div class="app-urls-editor-title">
        <h3>{{ title }}</h3>
      </div>
      <v-container fluid>
        <div>
          <v-text-field
            id="cs-auth0-user-directory-url-input"
            v-model.trim="auth0UserDirectoryUrl"
            label="CS Auth0 User Directory Url (Read Only)"
            variant="underlined"
            color="primary"
            readonly
          />

          <v-text-field
            id="policy-connect-url-input"
            v-model.trim="policyConnectUrl"
            label="PolicyConnect Url"
            variant="underlined"
            color="primary"
            :rules="rules"
          />

          <v-text-field
            id="policy-plus-url-input"
            v-model.trim="policyPlusUrl"
            label="PolicyPlus Url"
            variant="underlined"
            color="primary"
            :rules="rules"
          />

          <v-text-field
            id="assurance-url-input"
            v-model.trim="assuranceUrl"
            label="Assurance Url"
            variant="underlined"
            color="primary"
            :rules="rules"
          />

          <v-text-field
            id="plan-check-go-url-input"
            v-model.trim="planCheckGoUrl"
            label="PlanCheckGo Url"
            variant="underlined"
            color="primary"
            :rules="rules"
          />

          <v-text-field
            id="safe-trip-builder-url-input"
            v-model.trim="safeTripBuilderUrl"
            label="SafeTripBuilder Url"
            variant="underlined"
            color="primary"
            :rules="rules"
          />

          <v-text-field
            id="compli-learn-portal-url-input"
            v-model.trim="compliLearnPortalUrl"
            label="CompliLearnPortal Url"
            variant="underlined"
            color="primary"
            :rules="rules"
          />

          <v-text-field
            id="go1-url-input"
            v-model.trim="go1Url"
            label="Go1 Url"
            variant="underlined"
            color="primary"
            :rules="rules"
          />
        </div>
        <div class="edit-app-urls-actions">
          <v-spacer></v-spacer>
          <cs-button
            id="save-app-urls-btn"
            primary
            class="mb-2"
            :disabled="!canSave"
            label="Save"
            @click="onSaveAppUrlsBtn"
          >
          </cs-button>
        </div>
      </v-container>
    </div>
    <cs-form-dialog
      id="app-urls-editor-save-confirm-dlg"
      v-model="confirmationDialog"
      heading="Save App Urls"
      :primary-action="{
        label: 'Yes & Apply'
      }"
      :secondary-action1="{
        label: 'No'
      }"
      @primary-click="onApplyBtn"
      @secondary1-click="confirmationDialog = false"
    >
      <template #cs-form-dialog-content>
        <div id="confirmation-message">This action cannot be undone. Are you sure?</div>
      </template>
    </cs-form-dialog>
  </div>
</template>

<script>
import { CSBase, CSFormDialog, CSButton } from '@complispace/cs-design-system';
import { mapGetters } from 'vuex';
import { soul } from '@/dependency-injection';
import isValidAppUrl from '@/helpers/isValidAppUrl';

export default {
  name: 'AppUrlsEditor',

  components: {
    'cs-form-dialog': CSFormDialog,
    'cs-button': CSButton
  },

  extends: CSBase,

  data() {
    return {
      confirmationDialog: false,
      stopDialog: false,
      rules: [(value) => isValidAppUrl(value) || 'URL is not valid'],
      policyConnectUrl: '',
      policyPlusUrl: '',
      assuranceUrl: '',
      planCheckGoUrl: '',
      safeTripBuilderUrl: '',
      compliLearnPortalUrl: '',
      go1Url: '',
      auth0UserDirectoryUrl: ''
    };
  },

  computed: {
    ...mapGetters({
      appUrls: 'organization/appUrlsEditorView',
      displayName: 'organization/displayName',
      organizationId: 'organization/organizationId'
    }),

    title() {
      if (this.displayName) {
        return `${this.displayName} application urls`;
      }

      return '';
    },

    canSave() {
      if (!this.organizationId) {
        return false;
      }

      if (!this.appUrls) {
        return false;
      }

      if (this.noUrls() && this.appUrls.length === 0) {
        return false;
      }

      return [
        this.policyConnectUrl,
        this.policyPlusUrl,
        this.assuranceUrl,
        this.planCheckGoUrl,
        this.safeTripBuilderUrl,
        this.compliLearnPortalUrl,
        this.go1Url
      ].every(isValidAppUrl);
    }
  },
  watch: {
    appUrls() {
      this.initAppUrls();
    }
  },

  async mounted() {
    this.initAuth0UserDirectoryUrl();
    await this.fetchOrganization();
    this.initAppUrls();
  },

  methods: {
    async fetchOrganization() {
      try {
        if (this.organizationId) {
          await this.$store.dispatch('organization/fetchOrganization');
        } else {
          await this.$store.dispatch('organization/init');
        }
      } catch (e) {
        this.showErrorAlert('An error occured when retrieving organization details.', true);
      }
    },

    noUrls() {
      return (
        !this.policyConnectUrl &&
        !this.policyPlusUrl &&
        !this.assuranceUrl &&
        !this.planCheckGoUrl &&
        !this.safeTripBuilderUrl &&
        !this.compliLearnPortalUrl &&
        !this.go1Url
      );
    },

    async onSaveAppUrlsBtn() {
      this.confirmationDialog = true;
    },

    findAppUrl(appName) {
      const appConfig = this.appUrls.find((it) => it[appName]);
      const appUrl = appConfig ? appConfig[appName] : '';
      return appUrl;
    },

    initAppUrls() {
      if (this.appUrls) {
        this.policyConnectUrl = this.findAppUrl('policyConnect');
        this.policyPlusUrl = this.findAppUrl('policyPlus');
        this.assuranceUrl = this.findAppUrl('assurance');
        this.planCheckGoUrl = this.findAppUrl('planCheckGo');
        this.safeTripBuilderUrl = this.findAppUrl('safeTripBuilder');
        this.compliLearnPortalUrl = this.findAppUrl('compliLearnPortal');
        this.go1Url = this.findAppUrl('go1');
      }
    },

    async updateOrganization() {
      try {
        await soul.updateAppUrlsByOrganizationId(
          this.organizationId,
          this.policyConnectUrl,
          this.policyPlusUrl,
          this.assuranceUrl,
          this.planCheckGoUrl,
          this.safeTripBuilderUrl,
          this.compliLearnPortalUrl,
          this.go1Url
        );
      } catch (error) {
        this.showErrorAlert('An error occured when updating the organization appUrls.', true);
      }
    },

    async onApplyBtn() {
      this.setLoading(true);
      try {
        await this.updateOrganization();
        await this.fetchOrganization();

        this.confirmationDialog = false;

        await this.$nextTick();
        this.showSuccessAlert('Application Urls has been updated successfully.');

        this.clearLoading();
      } catch (err) {
        this.clearLoading();
        this.showErrorAlert(err.message, true);
      }
    },

    initAuth0UserDirectoryUrl() {
      this.auth0UserDirectoryUrl = `${window.location.origin}/`;
    }
  }
};
</script>

<style scoped>
.edit-app-urls-actions {
  display: flex;
}

.container {
  padding: 0;
}

.app-urls-editor-title {
  min-width: 1px;
  height: 32px !important;
}
</style>
