import mutations from './mutations';
import getters from './getters';

const state = {
  name: ''
};
const actions = {};

const store = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};

export default store;
